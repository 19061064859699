<template>
  <div class="phone-button">
    <a class="phone-button__link" :href="`tel:${phone}`" target="_blank">
      <IconComponent category="vuesax-bold" name="call" />
    </a>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "PhoneButtonComponent",
  components: { IconComponent },
  computed: {
    phone() {
      return this.$store.state.department.hot_line
        ? this.$store.state.department.hot_line
        : this.$store.state.department.phone;
    },
  },
};
</script>

<style lang="stylus">
.phone-button {
  display none
  position fixed
  bottom 142px
  right 24px
  z-index 1
  align-items center
  +below(1280px) {
    display flex
    bottom 102px
  }
  //+below(440px) {
  //  bottom 102px
  //}

  &__link {
    display flex
    justify-content center
    align-items center
    width: 65px
    height: 65px
    border-radius 50%

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.7) 2px 2px 11px;
    }

    .icon {
      width 65px
      height: 65px;
      background: var(--main);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 100px;

      svg {
        width 20px
        height 20px

        path {
          fill var(--white)
        }
      }
    }
  }
}
</style>
